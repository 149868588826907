<template>

	<div class="wrap">
		<el-page-header @back="$router.back()" :content="title" class="margin-bottom margin-top-xs"></el-page-header>
		<el-alert title="操作提示" type="success" class="margin-bottom">
			<div>
				1. 标识“*”的选项为必填项，其余为选填项。<br />
				2. 请按提示栏信息进行商品添加。
			</div>
		</el-alert>
		<div class="box">
			<div class="form_box">

				<el-form ref="form" :model="form" label-width="200px" v-loading="loading">
					<el-form-item label="护理日期" :rules="rules.required">
						<div class="form-item-box" prop="nursing_date">
							<el-date-picker v-model="form.nursing_date" type="date" placeholder="请选择日期"
								format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">
							</el-date-picker>
						</div>
					</el-form-item>
					
					<el-form-item label="消费项目" prop="consumer_care" :rules="rules.required">
						<el-input maxlength="100" v-model="form.consumer_care" placeholder="请填写消费项目"></el-input>
					</el-form-item>
					
					<el-form-item label="护理项目进展" prop="nursing_progress" :rules="rules.required">
						<el-input maxlength="100" v-model="form.nursing_progress" placeholder="请填写护理项目进展"></el-input>
					</el-form-item>
					
					<el-form-item label="次数" prop="number" :rules="rules.required">
						<el-input maxlength="100" v-model="form.number" placeholder="请填写次数"></el-input>
					</el-form-item>
					
					<el-form-item label="使用产品和物料领取单编号" prop="product_materials" :rules="rules.required">
						<el-input maxlength="100" v-model="form.product_materials" placeholder="请填写使用产品和物料领取单编号"></el-input>
					</el-form-item>
					
					
					<el-form-item label="顾客姓名" prop="customer_auto" :rules="rules.required">
						<el-input maxlength="30" v-model="form.customer_auto" placeholder="请填写顾客姓名"></el-input>
					</el-form-item>
					
					<el-form-item label="顾客是否确认" prop="customer_yes" :rules="rules.required">
						<div class="form-item-box">
							<el-radio v-model="form.customer_yes" :label="1">是</el-radio>
							<el-radio v-model="form.customer_yes" :label="0">否</el-radio>
						</div>
					</el-form-item>
					
					<el-form-item label="效果反应" prop="effect_response" :rules="rules.required">
						<el-input maxlength="30" v-model="form.effect_response" placeholder="请填写效果反应"></el-input>
					</el-form-item>
					
					<!-- <el-form-item label="服务满意分数" prop="scoring" :rules="rules.required">
						<el-input maxlength="30" v-model="form.scoring" placeholder="请填写服务满意分数(0-100)"></el-input>
					</el-form-item> -->
					<el-form-item label="服务满意分数" prop="scoring" :rules="rules.required">
						<div class="form-item-box">
							<el-radio v-model="form.scoring" :label="1">非常不满意</el-radio>
							<el-radio v-model="form.scoring" :label="2">不满意</el-radio>
							<el-radio v-model="form.scoring" :label="3">一般</el-radio>
							<el-radio v-model="form.scoring" :label="4">满意 </el-radio>
							<el-radio v-model="form.scoring" :label="5">非常满意</el-radio>
						
						</div>
					</el-form-item>
					
					<el-form-item label="老师姓名" prop="technician_auto" :rules="rules.required">
						<el-input maxlength="30" v-model="form.technician_auto" placeholder="请填写老师姓名"></el-input>
					</el-form-item>
					
					<el-form-item label="老师是否确认" prop="technician_yes" :rules="rules.required">
						<div class="form-item-box">
							<el-radio v-model="form.technician_yes" :label="1">是</el-radio>
							<el-radio v-model="form.technician_yes" :label="0">否</el-radio>
						</div>
					</el-form-item>
					
					<el-form-item label="备注" prop="remark" >
						<el-input  v-model="form.remark"  type="textarea" placeholder="请填写备注"></el-input>
					</el-form-item>
					
					

					<el-form-item>
						<el-button type="primary" @click="onSubmit()">保存</el-button>
						<el-button @click="$router.back()">取消</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>
	</div>
</template>
<script>
	import rules from '../../../common/common.rules.js'
	export default {
		data() {
			return {
				loading: false,
				rules,
				form: {
					id: this.$route.params.id, // int 服务ID
					nursing_date: "", //护理日期
					consumer_care:"",//消费项目
					number:"",//次数
					product_materials:"",//使用产品和物料领取单编号
					nursing_progress: "", //护理进展
					customer_auto: "", //顾客签名
					customer_yes: '', //顾客确认 1:是,0:否
					effect_response: "", //效果反应
					scoring: "", //服务满意分数
					technician_auto: "", //老师签名
					technician_yes: '' ,//老师确认 1:是,0:否
					remark:''//备注

				}
			};
		},
		computed: {
			title() {
				if (this.form.id) {
					return '编辑记录'
				} else {
					return '添加记录'
				}
			}
		},
		mounted() {
			if (this.form.id) {
				this.getInfo()
			}


		},
		methods: {
			//获取详情
			getInfo() {
				this.$api.tablewx.getInfo({
					id: this.form.id
				}).then(res => {
					console.log(res)
					res.data.customer_yes = Number(res.data.customer_yes)
					res.data.technician_yes = Number(res.data.technician_yes)
					this.form = res.data
				})
			},

			onSubmit() {
				console.log(this.form.id)
				this.$refs.form.validate(valid => {
					if (valid) {
						if (this.form.id == undefined) {
							const loading = this.$loading();
							this.$api.tablewx.addCustomerCareList(this.form).then(res => {
								loading.close();
								if (res.code == 200) {
									// 保存成功
									this.$message.success(`保存成功`);
									this.$router.back();
								} else {
									this.$message.error(res.msg);
								}
							}).catch(err => {
								loading.close();
								this.$alert(err);
							});
						} else {
							const loading = this.$loading();
							this.$api.tablewx.editCustomerCareList(this.form).then(res => {
								loading.close();
								if (res.code == 200) {
									// 保存成功
									this.$message.success(`修改成功`);
									this.$router.back();
								} else {
									this.$message.error(res.msg);
								}
							}).catch(err => {
								loading.close();
								this.$alert(err);
							});
						}



					} else {
						this.$message.warning(`请检查输入错误`);
						return false;
					}
				});
			}
		},
	};
</script>

<style scoped lang="scss">
	.form_box {

		.el-input,
		.el-textarea {
			width: 500px;
		}
	}
</style>
